import { Analytics, getAnalytics } from "firebase/analytics";
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { Auth, getAuth, OAuthProvider } from "firebase/auth";
import { FirebasePerformance, getPerformance } from "firebase/performance";
import { getRemoteConfig, RemoteConfig } from "firebase/remote-config";

import { isNonProd } from "./util";

type Config = {
  firebaseConfig: FirebaseOptions;
};

const firebaseConfig: { [env: string]: Config } = {
  dev: {
    firebaseConfig: {
      apiKey: "AIzaSyBu50lUuI62-P1EgJr9uHRKZJQ8UQnMGtw",
      // authDomain: "vertex-ai-chat-demo-dev-451256.firebaseapp.com",
      authDomain: "login.chat-demo.gcloud.cloud-ops.co.uk",
      projectId: "vertex-ai-chat-demo-dev-451256",
      storageBucket: "vertex-ai-chat-demo-dev-451256.appspot.com",
      messagingSenderId: "529042705038",
      appId: "1:529042705038:web:0db111c28bd385c66c17df",
      measurementId: "G-BRKRB0Q8C9",
    },
  },
  prod: {
    firebaseConfig: {
      apiKey: "AIzaSyBYuUkTagXZ0lpGjStM42DloSrRrVxj3nM",
      // authDomain: "vertex-ai-chat-demo-pro-705461.firebaseapp.com",
      authDomain: "login.kpmgava.com",
      projectId: "vertex-ai-chat-demo-pro-705461",
      storageBucket: "vertex-ai-chat-demo-pro-705461.appspot.com",
      messagingSenderId: "698578642938",
      appId: "1:698578642938:web:6341cef77da43e786ba643",
      measurementId: "G-32LE4F4Q5B",
    },
  },
};

const getFirebaseConfig = (): Config => {
  const envName = import.meta.env.VITE_FIREBASE_ENV;

  if (!envName) {
    throw new Error(`Env name not specified. Please set VITE_FIREBASE_ENV`);
  }

  if (envName in firebaseConfig) {
    return firebaseConfig[envName];
  }

  throw new Error(
    `Env name ${envName} not found. Please check VITE_FIREBASE_ENV`,
  );
};

let firebaseApp: FirebaseApp | null = null;
let firebaseAuth: Auth | null = null;
let firebaseAnalytics: Analytics | null = null;
let firebasePerformance: FirebasePerformance | null = null;
let firebaseRemoteConfig: RemoteConfig | null = null;
let provider: OAuthProvider | null = null;

const getFirebaseApp = (): FirebaseApp => {
  if (firebaseApp === null) {
    firebaseApp = initializeApp(getFirebaseConfig().firebaseConfig);
  }
  return firebaseApp;
};

export const getFirebaseAuth = (): Auth => {
  if (firebaseAuth === null) {
    firebaseAuth = getAuth(getFirebaseApp());
  }
  return firebaseAuth;
};

export const getMicrosoftProvider = (): OAuthProvider => {
  if (provider === null) {
    provider = new OAuthProvider("microsoft.com");
    provider.setCustomParameters({
      tenant: "deff24bb-2089-4400-8c8e-f71e680378b2",
    });
    provider.addScope("profile");
  }
  return provider;
};

export const getFirebasePerformance = (): FirebasePerformance => {
  if (firebasePerformance === null) {
    firebasePerformance = getPerformance(getFirebaseApp());
  }
  return firebasePerformance;
};

export const getFirebaseAnalytics = (): Analytics => {
  if (firebaseAnalytics === null) {
    firebaseAnalytics = getAnalytics(getFirebaseApp());
  }
  return firebaseAnalytics;
};

export const getFirebaseRemoteConfig = (): RemoteConfig | null => {
  if (firebaseRemoteConfig === null) {
    try {
      firebaseRemoteConfig = getRemoteConfig(getFirebaseApp());
    } catch {
      return null;
    }
    firebaseRemoteConfig.settings.minimumFetchIntervalMillis = isNonProd
      ? 3600000
      : 43200000; // One hour in non-prod, 12hours in prod
    firebaseRemoteConfig.defaultConfig = {};
  }
  return firebaseRemoteConfig;
};
