import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import avalogo from "common/ava-darkgray.svg";
import { GradientLoader } from "common/Components/GradientLoader";
import { logError } from "common/errors";
import { isNonProd } from "common/util";
import * as ciap from "gcip-iap";
import { useEffect, useState } from "react";
import { RiMicrosoftFill } from "react-icons/ri";

import {
  AppAuthenticationHandler,
  signInHandler,
} from "./AppAuthenticationHandler";
import kpmglogo from "./logo.png";

const defaultUrl = isNonProd
  ? "https://chat-demo.gcloud.cloud-ops.co.uk/"
  : "https://kpmgava.com/";

export const App = () => {
  const [error, setError] = useState<Error | ciap.CIAPError | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [originalUrl, setOriginalUrl] = useState<string | null>(null);

  const errorHandler = (error: Error | ciap.CIAPError) => {
    logError(error as Error);
    setError(error);
  };

  useEffect(() => {
    const get = async () => {
      const appAuthHandler = new AppAuthenticationHandler(
        errorHandler,
        setIsLoading,
      );

      let originalUrl = null;
      try {
        const ciapInstance = new ciap.Authentication(appAuthHandler);
        originalUrl = await ciapInstance.getOriginalURL();
        setOriginalUrl(originalUrl);
        ciapInstance.start();
      } catch (error: unknown) {
        const e = error as Error;

        if (
          e.message ==
          "Authentication token has expired. Please visit the URL that redirected you to this page again to restart the authentication process."
        ) {
          window.location.replace(
            originalUrl === null ? defaultUrl : originalUrl,
          );
          return;
        }

        errorHandler(e);
      }
    };
    get();
  }, []);

  return (
    <GradientLoader headerHeight={"0px"}>
      <Center>
        <Card
          my={10}
          w={{ base: "60vw", "2xl": "30vw" }}
          mx="auto"
          rounded="lg"
          role="main"
        >
          <CardHeader>
            <VStack>
              <Image alt={"KPMG Logo"} src={kpmglogo} w="5rem" />
              <Image alt={"AVA Logo"} src={avalogo} w="8rem" />
            </VStack>
          </CardHeader>
          <CardBody>
            <VStack>
              <Button
                variant={"solid"}
                bg={"kpmgblue"}
                color="white"
                isLoading={isLoading}
                _hover={{ color: "gray.800", bg: "kpmglightblue" }}
                onClick={() => signInHandler(errorHandler)}
                leftIcon={<RiMicrosoftFill />}
                size="lg"
              >
                Sign in
              </Button>
            </VStack>
          </CardBody>
          {error !== null && (
            <CardFooter>
              <Alert status="error">
                <AlertIcon />
                <AlertDescription>
                  <VStack spacing={2}>
                    <Text>{error.toString()}</Text>
                    <Text>
                      Please{" "}
                      <Button
                        size="sm"
                        colorScheme="red"
                        onClick={() => {
                          window.location.replace(
                            originalUrl === null ? defaultUrl : originalUrl,
                          );
                        }}
                      >
                        go back
                      </Button>{" "}
                      & try again.
                    </Text>
                  </VStack>
                </AlertDescription>
              </Alert>
            </CardFooter>
          )}
        </Card>
      </Center>
    </GradientLoader>
  );
};
