import { logEvent } from "firebase/analytics";
import { FallbackProps } from "react-error-boundary";

import { getFirebaseAnalytics } from "../Firebase";
import { isDev } from "../util";

export const ErrorFallback = (props: FallbackProps) => {
  try {
    logEvent(getFirebaseAnalytics(), "exception", {
      description: props.error.message,
      fatal: true,
    });
  } catch (error) {
    console.log("Error", error);
    //noop
  }

  return (
    <div
      role="alert"
      style={{ margin: "0 auto", textAlign: "center", fontFamily: "verdana" }}
    >
      <h1>Something went wrong!</h1>
      <p>
        Please <button onClick={() => location.reload()}>refresh</button> & try
        again.
      </p>
      <p>
        If this error persists, please contact{" "}
        <a href="mailto:ukfmava@kpmg.co.uk">ukfmava@kpmg.co.uk</a>
      </p>
      {isDev && (
        <>
          <hr />
          <div style={{ textAlign: "left", color: "red", fontSize: "1.8em" }}>
            <pre style={{ fontSize: "2em" }}>{props.error.message}</pre>
            <pre>{props.error.stack}</pre>
          </div>
          <hr />
        </>
      )}
    </div>
  );
};
