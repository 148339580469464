import "@fontsource/open-sans";
import "@fontsource/raleway";

import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    kpmgblue: "#00338D",
    kpmgcobalt: "#1E49E2",
    kpmgdarkblue: "#0C233C",
    kpmglightblue: "#ACEAFF",
    kpmgpacificblue: "#00B8F5",
    kpmgpurple: "#7213EA",
    kpmgpink: "#FD349C",
  },
  fonts: {
    heading: `'Open Sans', sans-serif`,
    body: `'Raleway', sans-serif`,
  },
});
