import "@fontsource/raleway/400.css";
import "@fontsource/open-sans/700.css";

import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { ErrorFallback } from "common/Components/ErrorFallback";
import { setupDatadog } from "common/datadog";
import { logError } from "common/errors";
import { getFirebasePerformance } from "common/Firebase";
import { theme } from "common/theme";
import { banner } from "common/util";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";

import { App } from "./App";

banner();

setupDatadog("ava-login");

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError={(error: Error, _info: React.ErrorInfo) => logError(error)}
    >
      <ColorModeScript />
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);

getFirebasePerformance();
