import { datadogRum } from "@datadog/browser-rum";

import { isDev, Logger, logger } from "./util";

export const logError = (error: Error, _logger: Logger | null = null) => {
  (_logger || logger("ava", "red")).error(error);
  if (!isDev) {
    datadogRum.addError(error);
  }
};
