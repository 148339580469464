import { Box, Icon, IconButton, Tooltip } from "@chakra-ui/react";
import { keyframes } from "@chakra-ui/system";
import { PropsWithChildren, useState } from "react";
import { RiPauseLine, RiPlayLine } from "react-icons/ri";

interface GradientLoaderProps {
  headerHeight: string;
}

const gradient = keyframes({
  "0%": {
    backgroundPosition: "0% 0%",
  },
  "50%": {
    backgroundPosition: "100% 100%",
  },
  "100%": {
    backgroundPosition: "0% 0%",
  },
});

const wave = keyframes({
  "0%": {
    transform: "translateX(1)",
  },
  "25%": {
    transform: "translateX(-25%)",
  },
  "50%": {
    transform: "translateX(-50%)",
  },
  "75%": {
    transform: "translateX(-25%)",
  },
  "100%": {
    transform: "translateX(1)",
  },
});

export const GradientLoader = (
  props: PropsWithChildren<GradientLoaderProps>,
) => {
  const { headerHeight } = props;

  const [isRunning, setIsRunning] = useState<boolean>(true);

  const playState = isRunning ? "running" : "paused";

  return (
    <Box
      minH={`calc(100vh - ${headerHeight})`}
      minW="100vw"
      width="100%"
      animation={`${gradient} 30s ease infinite`}
      bgGradient="linear(to-br, kpmglightblue, kpmgpacificblue, kpmgpurple, kpmgpink)"
      backgroundSize="400% 400%"
      backgroundAttachment="fixed"
      top={headerHeight}
      position="absolute"
      sx={{
        animationPlayState: playState,
      }}
    >
      <Box
        bg="whiteAlpha.200"
        borderRadius="1000% 1000% 0 0"
        position="fixed"
        width="200%"
        height="50vh"
        animation={`${wave} 15s -3s ease infinite`}
        sx={{
          animationPlayState: playState,
        }}
        transform="translate3d(0,0,0)"
        bottom="0"
        left="0"
      />
      <Box
        bg="whiteAlpha.200"
        borderRadius="1000% 1000% 0 0"
        position="fixed"
        width="200%"
        height="50vh"
        animation={`${wave} 27s ease reverse infinite`}
        sx={{
          animationPlayState: playState,
        }}
        transform="translate3d(0,0,0)"
        bottom="-1.25em"
        left="0"
      />
      <Box
        bg="whiteAlpha.200"
        borderRadius="1000% 1000% 0 0"
        position="fixed"
        width="200%"
        height="50vh"
        animation={`${wave} 30s -1s ease reverse infinite`}
        sx={{
          animationPlayState: playState,
        }}
        transform="translate3d(0,0,0)"
        bottom="-2.5em"
        left="0"
      />
      {props.children}
      <Tooltip label={isRunning ? "Pause Animation" : "Play Animation"}>
        <IconButton
          position="absolute"
          bottom="0"
          right="0"
          onClick={() => setIsRunning(!isRunning)}
          aria-label={isRunning ? "Pause Animation" : "Play Animation"}
          icon={<Icon as={isRunning ? RiPauseLine : RiPlayLine} />}
        >
          Pause
        </IconButton>
      </Tooltip>
    </Box>
  );
};
